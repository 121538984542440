import { commonApi, OffsetLimit, StatusPagesResponse, StatusResponse } from './api';
import { AccessRequestStatus, UserRequest } from './users';


export interface NotificationResponse<T> {
    id: 35,
    isnew: true,
    added: string,
    template: string, // TODO список допустимых значений
    prio: 0,
    context: T
}

export interface NotificationAccessContext {
    request: {
        id: number,
        url: string,
        sender: {
            user: string,
            email: string,
            phone: string,
            company: string,
            user_id: number,
            lastname: string,
            firstname: string
        },
        status: AccessRequestStatus,
        content: {
            id: 14,
            title: string,
            title_eng: string
        },
        lastmsg: {
            user: string,
            status: AccessRequestStatus,
            message: string,
            timestamp: string
        },
        updated: string,
        receiver: {
            user: string,
            email: string,
            phone: string,
            company: string,
            user_id: number,
            lastname: string,
            firstname: string
        }
    }
}

export interface NotificationNewUserContext extends UserRequest {
    last_updated: string,
    email_verification: string
}

export const notifyApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getNewNotificationsUser: builder.query<StatusResponse<NotificationResponse<any>>, void>({
            query: () => 'be/api/notification/poll/?clear=False',
            providesTags: () => [{ type: 'Notify' }]
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getNotificationsUser: builder.query<StatusPagesResponse<NotificationResponse<any>>, OffsetLimit>({
            // query: () => `be/api/notification/`,
            query: (params: OffsetLimit) => `be/api/notification/?${Object.keys(params).filter(k => params[k]).map(k => k + '=' + params[k]).join('&')}`,
            providesTags: () => [{ type: 'Notify' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getNewNotificationsAccess: builder.query<StatusResponse<NotificationResponse<NotificationAccessContext>>, void>({
            query: () => 'be/api/notification/poll/?clear=False',
            providesTags: () => [{ type: 'Notify' }]
        }),
        getNotificationsAccess: builder.query<StatusPagesResponse<NotificationResponse<NotificationAccessContext>>, void>({
            query: () => 'be/api/notification/',
            providesTags: () => [{ type: 'Notify' }]
        }),
    }),
})

export const { 
    useGetNewNotificationsAccessQuery, useGetNewNotificationsUserQuery, useGetNotificationsAccessQuery, useGetNotificationsUserQuery,
    useLazyGetNotificationsUserQuery,
} = notifyApi;
