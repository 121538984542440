interface Header1Props {
    title?: string,
    children?: React.ReactNode;
}

function Header1({ title, children }: Header1Props) {
    return <h1 className="content-add__heading" title={title} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{children}</h1>;
}

export default Header1;
