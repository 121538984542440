import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { commonApi, roleReducer } from '../services/api';
import authReducer from '../services/api';
import { useDispatch } from 'react-redux';
import { transcodeApi } from '../services/transcode';
import { userApi } from '../services/users';
import { contentApi } from '../services/content';
import { notifyApi } from '../services/notifications';


const rootReducer = combineReducers({
    [commonApi.reducerPath]: commonApi.reducer,
    [transcodeApi.reducerPath]: transcodeApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [notifyApi.reducerPath]: notifyApi.reducer,
    auth: authReducer,
    role: roleReducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
            getDefaultMiddleware().concat(commonApi.middleware).concat(userApi.middleware),
        preloadedState,
    })
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
