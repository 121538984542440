interface ContentProps {
    withoutInner?: boolean,
    children?: React.ReactNode,
}

function Content({ withoutInner, children }: ContentProps) {
    return (
        <div className="content">
            {withoutInner ?
                <>
                    {children}
                </>
                :
                <div className="content__inner">
                    {children}
                </div>
            }
        </div>
    );
}

export default Content;
