import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NotificationResponse, useLazyGetNotificationsUserQuery } from '../services/notifications';
import { useGetMediaHeaderMutation, useGetMetaDataMutation, useLazyGetContentUnitMediaQuery } from '../services/content';
import Content from '../components/mplat/Content';
import ContentShadow from '../components/mplat/ContentShadow';
import MainHeader from '../components/mplat/MainHeader';
import { ReactComponent as Notify } from '../assets/svg/Notify.svg';
import MultiSelect from '../components/mplat/MultiSelect';
import { ReactComponent as Filter } from '../assets/svg/filter.svg';
import { ReactComponent as FileDoc } from '../assets/svg/file-doc.svg';
import { ReactComponent as Bell } from '../assets/svg/notification.svg';
import MobileFilter from '../components/mplat/MobileFilter';
import { MobileFilterItem } from '../components/mplat/MobileFilterItem';
import { DatePicker } from '../components/mplat/DatePicker';
import { MobileDateFilterItem } from '../components/mplat/MobileDateFilterItem';
import saveAs from 'file-saver';
import { BASE_URL } from '../services/reauth';
import { useRole } from '../hooks';
import { extractErrorDetail, ROLE } from '../components/common';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { ErrorModal } from '../components/mplat/Modals/ErrorModal';
import { useGetClientsQuery } from '../services/users';
import { Spinner } from '../components/mplat/Spinner';


export enum NOTIFY_SEVERITY {
    URGENT = '_urgent',
    HIGH = '_high',
    LOW = '_low',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMessage(item: NotificationResponse<any>, onDownload: any, highlight?: string): JSX.Element {
    try {
        switch (item.template) {
            case 'content.unit.access.request.new':
                return (<>
                    Потребитель
                    {' '}
                    <Link className="link-accent" to={`/subscribers?user=${item.context.request?.sender?.company || item.context.request?.sender?.profile.company}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.request?.sender?.company || item.context.request?.sender?.profile.company}
                        />
                        »
                    </Link>
                    {' '}
                    запрашивает разрешение на загрузку
                    {' '}
                    <Link className="link-accent" to={`/content/${item.context.request.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.request.content.title}
                        />
                        »
                    </Link>
                </>)
            case 'support.message.new':
                return (<>
                    Сообщение в поддержку: Пользователь
                    {' '}
                    <Link className="link-accent" to={`/admin?user=${item.context.request?.sender.company}`}>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.request?.sender.company}
                        />
                    </Link>
                    {' '}
                    пишет
                    "{item.context.request?.message}"
                </>);
            case 'feedback.message.new':
                return (<>
                    Получено новое сообщение по форме обратной связи:
                    {' '}
                    "{item.context.request?.message}"
                </>);
            case 'user.request.new':
                return (<>
                    Заявка на регистрацию: <strong>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={`${item.context.profile.lastname} ${item.context.profile.firstname}`}
                        />
                    </strong> компания <strong>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.profile.company}
                        />
                    </strong>
                </>);
            case 'content.unit.access.request.granted':
                return (
                    <>
                        Получено разрешение на скачивание
                        {' '}
                        <Link className="link-accent" to={`/content/view/${item.context.request.content.id}`}>
                            «
                            <Highlighter
                                highlightClassName="search__results-highlight"
                                searchWords={[highlight || '']}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={item.context.request.content.title}
                            />
                            »
                        </Link>
                        {' '}
                        <button className="btn _min" onClick={() => onDownload(item.context.request.content.id, item.context.request.content.title)}>
                            <Trans>Download</Trans>
                        </button>
                    </>
                );
            case 'content.unit.access.request.pending':
                return (
                    <>
                        Запрос на скачивание
                        {' '}
                        <Link className="link-accent" to={`/content/view/${item.context.request.content.id}`}>
                            «
                            <Highlighter
                                highlightClassName="search__results-highlight"
                                searchWords={[highlight || '']}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={item.context.request.content.title}
                            />
                            »
                        </Link>
                        {' '}
                        находится на рассмотрении
                    </>
                );
            case 'content.unit.access.request.rejected':
                return (<>
                    Запрос на скачивание
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.request.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.request.content.title}
                        />
                        »
                    </Link>
                    {' '}
                    отклонен
                </>)
            case 'content.unit.send_to_user':
                return (<>
                    Поставщик
                    {' '}
                    <Highlighter
                        highlightClassName="search__results-highlight"
                        searchWords={[highlight || '']}
                        autoEscape={true}
                        highlightTag={'span'}
                        textToHighlight={item.context.sender.profile.company}
                    />
                    {' '}
                    открыл Вам доступ на скачивание
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.unit_agreed':
            case 'moderation.unit.agreed':
                return (<>
                    Модератор одобрил вашу единицу контента
                    {' '}
                    <Link className="link-accent" to={`/content/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.unit_published':
            case 'moderation.unit.published':
                return (<>
                    Поставщик
                    {' '}
                    <Highlighter
                        highlightClassName="search__results-highlight"
                        searchWords={[highlight || '']}
                        autoEscape={true}
                        highlightTag={'span'}
                        textToHighlight={item.context.content.owner.company}
                    /> опубликовал единицу контента
                    {' '}
                    <Link className="link-accent" to={`/content/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.unit_changed':
            case 'moderation.unit.changed':
                return (<>
                    Поставщик
                    {' '}
                    <Highlighter
                        highlightClassName="search__results-highlight"
                        searchWords={[highlight || '']}
                        autoEscape={true}
                        highlightTag={'span'}
                        textToHighlight={item.context.content.owner.company}
                    /> изменил описание единицы контента
                    {' '}
                    <Link className="link-accent" to={`/content/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.unit_filled':
            case 'moderation.unit.filled':
                return (<>
                    Модератор заполнил вашу единицу контента
                    {' '}
                    <Link className="link-accent" to={`/content/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.send_report':
                return (<>
                    Модератор отправил отчет о единице контента
                    {' '}
                    <Link className="link-accent" to={`/content/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'content.unit.new':
                return (<>
                    Доступен новый контент
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>)
            case 'content.unit.offer':
                return (<>
                    Получено новое предложение
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>)
            case 'user.account.activate':
                return (<>
                    Новый пользователь с ролью
                    {' '}
                    <Link className="link-accent" to={'/requests'}>
                        «{item.context.role.ru}»
                    </Link>
                </>)
            case 'orbox.task.failed':
                return (<>
                    Возникла ошибка при проверке медиафайла единицы контента:
                    {' '}
                    {item.context?.orbox?.url &&
                        <>
                            «
                            <Link className="link-accent" to={`/content/view/${item.context.orbox?.url}`}>
                                <Trans>Content</Trans>
                            </Link>
                            »
                        </>}
                    {' '}
                    <strong>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context?.orbox?.error}
                        />
                    </strong>
                </>)
            case 'orbox.check.failed':
                return (<>
                    Медиафайл единицы контента
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                    {' '}
                    не прошел проверку
                </>)
            default:
                return (<>
                    {JSON.stringify(item)}
                </>)
        }
    } catch {
        const content = JSON.stringify(item);
        return (<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={content}>
            {content}
        </div>)
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getType(item: NotificationResponse<any>) {
    switch (item.template) {
        case 'content.unit.new':
        case 'content.unit.access.request.new':
        case 'content.unit.access.request.granted':
        case 'content.unit.send_to_user':
        case 'content.unit.access.request.pending':
        case 'moderation.send_report':
        case 'moderation.unit_agreed':
        case 'moderation.unit.agreed':
        case 'moderation.unit_filled':
        case 'moderation.unit.filled':
        case 'moderation.unit_changed':
        case 'moderation.unit.changed':
            return NOTIFY_SEVERITY.LOW;
        case 'content.unit.access.request.rejected':
        case 'user.request.new':
        case 'content.unit.offer':
        case 'feedback.message.new':
        case 'support.message.new':
        case 'moderation.unit_published':
        case 'moderation.unit.published':
        case 'user.account.activate':
        case 'orbox.task.failed':
        case 'orbox.check.failed':
            return NOTIFY_SEVERITY.HIGH;
        default:
            return NOTIFY_SEVERITY.HIGH;
    }
}

interface NotificationItemProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: NotificationResponse<any>
    highlight?: string
}

export function NotificationItem({ item, highlight }: NotificationItemProps) {
    const [mediafilesRequest] = useLazyGetContentUnitMediaQuery();
    const [getMetaData] = useGetMetaDataMutation();
    const [getHeader] = useGetMediaHeaderMutation();
    const { t } = useTranslation();

    const [error, setError] = useState<string>('');
    const [errorTitle, setErrorTitle] = useState<string>('');

    const added = new Date(item.added);
    const H = added.getHours();
    const M = added.getMinutes();
    const HH = H < 10 ? '0' + H.toString() : H.toString();
    const MM = M < 10 ? '0' + M.toString() : M.toString();

    function onDownload(unitId: number, title: string) {
        mediafilesRequest(Number(unitId)).unwrap().then((data) => {
            getMetaData({ id: unitId as number }).unwrap()
                .then((metaData) => {
                    const filename = title;
                    saveAs(metaData, `${filename}.zip`);
                })
                .catch(e => {
                    setErrorTitle(t('Metadata download error'));
                    setError(extractErrorDetail(e));
                })

            if (!!data.results.mediafiles?.length) {
                data.results.mediafiles?.forEach(f => {
                    getHeader(BASE_URL.substring(0, BASE_URL.length - 1) + f.url).then(() => {
                        window.open(BASE_URL.substring(0, BASE_URL.length - 1) + f.url);
                    });
                });
            }
        }).catch((e) => {
            setErrorTitle(t('Get mediafiles error'));
            setError(extractErrorDetail(e));
        });
    }

    return (
        <div className={'notifications__item ' + getType(item)}>
            <ErrorModal isOpen={!!error} onClose={() => setError('')} content={error} title={errorTitle} />

            <div className="notifications__item-icon">
                <Bell />
            </div>
            <p className="notifications__item-copy" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {getMessage(item, onDownload, highlight)}
            </p>
            <p className="notifications__item-date">
                {added.toLocaleDateString()} · {`${HH}:${MM}`}
            </p>
        </div>
    );
}


function NotificationList() {
    const PAGE_SIZE = 12;

    const [current, setCurrent] = useState(0);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const role = useRole();

    const [date, setDate] = useState<Date | undefined>();
    // const { data } = useGetNotificationsUserQuery({ offset: current, limit: PAGE_SIZE, crdate: (date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`) || '' });
    const [refetch, { data }] = useLazyGetNotificationsUserQuery();
    const { data: clients } = useGetClientsQuery({ offset: 0, limit: 1000 });

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('Notifications');
    }, [t]);

    const [company, setCompany] = useState<string[]>([]);
    const [availCompany, setAvailCompany] = useState<{ value: string, label: string }[]>([]);
    const [notifyTypes, setNotifyTypes] = useState<string[]>([]);

    const [availNotifyTypes] = useState<{ value: string, label: string }[]>([
        { value: NOTIFY_SEVERITY.URGENT, label: 'Срочное' },
        { value: NOTIFY_SEVERITY.HIGH, label: 'Важное' },
        { value: NOTIFY_SEVERITY.LOW, label: 'Обычное' },
    ]);

    useEffect(() => {
        setCurrent(0);
    }, [company, date]);

    useEffect(() => {
        setIsFetched(true);
        refetch({ offset: current, limit: PAGE_SIZE, crdate: (date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`) || '' })
            .then(() => setIsFetched(false))
            .catch(() => setIsFetched(false));
    }, [current, date, refetch]);

    useEffect(() => {
        setHasMore(!!data?.results.next);
    }, [data?.results]);

    const handleNavigation = useCallback(() => {
        if ((document.body.scrollHeight - window.innerHeight) - window.scrollY < 300 && !isFetched) {
            if (hasMore) {
                setCurrent(data?.results.data.length || 0);
            }
        }
    }, [data?.results.data.length, hasMore, isFetched]);

    useEffect(() => {
        window.addEventListener('scroll', handleNavigation);

        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        if (Number(clients?.results.totals.all) <= Number(clients?.results.data.length)) {
            const uniqueCompany = new Set<string>();
            clients?.results.data.every(s => uniqueCompany.add(s.profile.company));
            const result: { value: string, label: string }[] = [];
            uniqueCompany.forEach((c) => result.push({ value: c, label: c }));
            result.sort((a, b) => {
                if (a.value < b.value) {
                    return -1;
                }
                if (a.value > b.value) {
                    return 1;
                }
                return 0;
            });
            setAvailCompany(result);
        }
    }, [clients]);

    const [filterVisible, setFilterVisible] = useState(false);
    const [filterCount, setFilterCount] = useState<number>(0);

    useEffect(() => {
        setFilterCount(
            company.length
            + notifyTypes.length
            + (date ? 1 : 0)
        )
    }, [company, notifyTypes.length, date]);

    return (
        <>
            <Content>
                <ContentShadow />
                <MainHeader label={t('Notifications')} icon={<Notify />} />

                <div className="main__content-filter">
                    <div className="main__controls">
                        <div className="main__controls-filter">
                            <div className="main__controls-filter-item">
                                <DatePicker value={date} placeholder="Дата уведомления" setValue={(v) => setDate(v)} />
                            </div>
                            <MultiSelect
                                className="main__controls-filter-item"
                                value={company}
                                options={availCompany}
                                setValue={(v) => setCompany(v)}
                                placeholder={role.role === ROLE.CONSUMER ? 'Поставщик' : 'Потребитель'}
                                minimized
                            />
                            <MultiSelect className="main__controls-filter-item" value={notifyTypes} options={availNotifyTypes} setValue={(v) => setNotifyTypes(v)} placeholder={'Тип уведомления'} minimized />
                            {/* TODO load into xls */}
                            <a href="#xls" className="main__types-file" target="_blank">
                                <FileDoc />
                                <Trans>Load into xls</Trans>
                            </a>
                        </div>
                        <div className="main__controls-filter-trigger" onClick={() => setFilterVisible(!filterVisible)}>
                            <Filter />
                            {!!filterCount && <span className="main__controls-filter-trigger-counter">{filterCount}</span>}
                        </div>
                    </div>
                </div>

                <div className="notifications">
                    {data?.results.data
                        .filter(item => notifyTypes.length === 0 || notifyTypes.find(notifyType => getType(item) === notifyType))
                        .filter(item => company.length === 0 || company.find(conpanyName => item.context.request?.sender?.company === conpanyName || item.context.request?.sender?.profile?.company === conpanyName))
                        .map(item => <NotificationItem key={item.id} item={item} />)}
                    {isFetched && <Spinner />}
                </div>

                <MobileFilter
                    filterVisible={filterVisible}
                    setFilterVisible={setFilterVisible}
                    onClean={() => {
                        setCompany([]);
                        setNotifyTypes([]);
                        setDate(undefined);
                    }}
                >
                    <MobileDateFilterItem value={date} label="Дата уведомления" placeholder="Выберите дату" setValue={(v) => setDate(v)} />
                    <MobileFilterItem label="Тип уведомления" options={availCompany} value={company} setValue={setCompany} />
                    <MobileFilterItem label="Потребитель" options={availNotifyTypes} value={notifyTypes} setValue={setNotifyTypes} />
                </MobileFilter>
            </Content>
        </>
    );
}

export default NotificationList;
