import { ChangeEvent, useEffect, useRef } from 'react';
import { useOnScreen } from '../../hooks';

interface TextInputProps {
    value?: string;
    placeholder?: string,
    required?: boolean,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    error?: string,
    type?: string
    disabled?: boolean,
    'data-test-id'?: string,
}

function TextInput({ value, placeholder, required, onChange, error, type, disabled, 'data-test-id': testId }: TextInputProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isVisible = useOnScreen(inputRef);
    const localType = type ? type : 'text';

    useEffect(() => {
        if (error && !isVisible) {
            const input = inputRef.current;
            const top = input?.offsetTop || 0;
            window.scrollTo(0, top - 40);
        }
        // eslint-disable-next-line
    }, [error]);

    return <div className="form__item">
        <input
            ref={inputRef}
            type={localType}
            className={'form__input' + ((error && (!value && required)) ? ' _error' : '')}
            required={required}
            value={value}
            placeholder={placeholder}
            onChange={(e) => { onChange && onChange(e); }}
            disabled={disabled}
            data-test-id={testId}
        />
        {(error && (!value && required)) && <span className="form__input-error-message">{error}</span>}
    </div>
}

export default TextInput;