import { changeLanguage, t } from 'i18next';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../assets/svg/ArrowRight.svg';
import { useInput } from '../../../hooks';
import { useLoginMutation } from '../../../services/api';
import { EmailValidationModal } from '../Modals/EmailValidationModal';
import { ForgotPasswordModal } from '../Modals/ForgotPasswordModal';
import { ErrorModal } from '../Modals/ErrorModal';


function HomeAuth() {
    const [login, { isLoading }] = useLoginMutation();
    const [empty, setEmpty] = useState<boolean>();
    const emptyUsername = t('Enter Email');
    const emptyPassword = t('Enter password');

    const navigate = useNavigate();
    const [username, resetUsername] = useInput('');
    const [password, resetPassword] = useInput('');
    const [error, setError] = useState('');
    const [showRegistration, setShowRegistration] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [showError, setShowError] = useState(false);
    const [modalErrorText, setModalErrorText] = useState('');

    function resetForm() {
        resetUsername();
        resetPassword();
    }

    function onLogin() {
        setError('');
        let isEmpty = false;
        if (!username.value) {
            isEmpty = true;
        }
        if (!password.value) {
            isEmpty = true;
        }
        setEmpty(isEmpty);
        if (isEmpty) {
            return;
        }
        login({ username: username.value, password: password.value }).unwrap().then(() => {
            resetForm();
            // TODO убрать после нормального перевода
            changeLanguage('ru');
            // window.location.reload();
            window.location.pathname === '/login' && navigate('/');
        }).catch((e) => {
            if (e) {
                if (e.status === 423) {
                    setModalErrorText('Уважаемый пользователь! Доступ к сайту временно приостановлен на период обновления версии. Мы отправим уведомление о начале работы сайта на Вашу электронную почту.');
                    setShowError(true);
                } else {
                    const detail = e.data.detail;
                    if (detail) {
                        setError(detail);
                    } else {
                        setError(JSON.stringify(e.data.response.status.msg));
                    }
                }
            } else {
                setError(e.message);
            }
        });
    }

    return (
        <div className="home-auth" data-testid="home-auth">
            <EmailValidationModal isOpen={showRegistration} onClose={() => setShowRegistration(false)} />
            <ForgotPasswordModal isOpen={showForgot} onClose={() => setShowForgot(false)} />
            <ErrorModal isOpen={showError} onClose={() => setShowError(false)} title={''} content={modalErrorText} />

            <div className="home-auth__login">
                <div className="home-auth__login-heading">
                    <Trans>Log In</Trans>
                </div>
                <form action="#login" className="form form_required home-auth__login-form">
                    <div className={'form__item home-auth__login-item _input' + ((error || (empty && !username.value)) ? ' _error' : '')}>
                        <input {...username} name="username" type="text" className={'form__input' + ((error || (empty && !username.value)) ? ' _error' : '')} required placeholder={t('Email')} />
                        {(empty && !username.value) && <div className="form__input-error-message">{emptyUsername}</div>}
                        {error && <div className="form__input-error-message">{error}</div>}
                    </div>
                    <div
                        className={'form__item home-auth__login-item _input' + ((error || (empty && !password.value)) ? ' _error' : '')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onLogin();
                            }
                        }}>
                        <input {...password} name="password" type="password" className={'form__input' + ((error || (empty && !password.value)) ? ' _error' : '')} required placeholder={t('Password')} />
                        {(empty && !password.value) && <div className="form__input-error-message">{emptyPassword}</div>}
                    </div>
                    <div className="form__item home-auth__login-item _submit">
                        <button className="btn _bd" name="sign_in" type="button" disabled={isLoading} onClick={(e) => {
                            e.preventDefault();
                            onLogin();
                        }}>
                            <Trans>Sign in</Trans>
                        </button>
                    </div>
                    <div className="home-auth__login-item _forgot _js-forgot-pass" onClick={() => setShowForgot(true)}>
                        <Trans>Forgot your password</Trans>?
                    </div>
                </form>
            </div>
            <div className="home-auth__reg">
                <a href="#register" className="btn _js-register" onClick={(e) => {
                    e.preventDefault();
                    setShowRegistration(true);
                }}>
                    <span className="btn__icon _right">
                        <Trans>Sign Up</Trans>
                        <Arrow />
                    </span>
                </a>
            </div>
        </div>
    )
}

export default HomeAuth;