import { useRole } from '../../hooks';
import { ROLE } from '../common';

interface ContentShadowProps {
    children?: React.ReactNode;
}

function ContentShadow({ children }: ContentShadowProps) {
    const role = useRole();
    return role.role === ROLE.CONSUMER ? <div className="content__shadow">{children}</div> : <div />;
}

export default ContentShadow;
