import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks';
import IndexPage from '../../pages/IndexPage';

function PrivateOutlet() {
    const auth = useAuth();
    return auth?.user ? (
        <Outlet />
    ) : (
        <IndexPage />
    )
}

export default PrivateOutlet;
