/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as Checked } from '../../assets/svg/Checked.svg'
import { ReactComponent as Close } from '../../assets/svg/Close.svg'
import { ReactComponent as ArrowDown } from '../../assets/svg/ArrowDown.svg'
import { useOutsideAlerter } from '../../hooks';
import { Trans } from 'react-i18next';


interface MultiSelectProps {
    value: any[],
    options: { label?: string, value: any, sys?: boolean, custom?: string }[],
    label?: string,
    placeholder?: string,
    top?: boolean,
    disabled?: boolean,
    minimized?: boolean,
    className?: string,
    createHeader?: string,
    createPlaceholder?: string,
    editable?: boolean,
    deletable?: boolean,
    dataTestId?: string,
    setValue?: (value: any[]) => void,
    onChange?: (option: { label?: string, value: any, custom?: string }[]) => void,
    onDelete?: (value: any) => boolean | Promise<boolean>,
    onCreate?: (label: string) => boolean | Promise<boolean>,
}

function MultiSelect(props: MultiSelectProps) {
    const { value, options, label, placeholder, top, createHeader, createPlaceholder } = props;
    const { disabled, minimized, className, editable, deletable, dataTestId } = props;
    const { setValue, onChange, onDelete, onCreate } = props;
    const [labels, setLabels] = useState<{ label?: string, value: any }[]>([]);
    const [val, setVal] = useState<any[]>(value);
    const [isExpand, setIsExpand] = useState(false);
    const [filter, setFilter] = useState('');
    const [effectiveOptions, setEffectiveOptions] = useState(options);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [activeCreate, setActiveCreate] = useState(false);
    const [createValue, setCreateValue] = useState('');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const refNew = useRef<HTMLInputElement | null>(null);

    const onDeleteLocal = useCallback((deleteValue: any) => {
        setEffectiveOptions((es) => es.filter((o) => o.value !== deleteValue));
        setVal((vs) => vs.filter((v) => v !== deleteValue));
        setLabels((ls) => ls.filter((o) => o.value !== deleteValue));
    }, []);

    const onCreateLocal = useCallback((createdValue: any) => {
        if (!createdValue || val.includes(createdValue)) {
            return false;
        }
        setEffectiveOptions((es) => [...es, { value: createdValue, label: createdValue }]);
        setVal((vs) => [...vs, createdValue]);
        setLabels((ls) => [...ls, { value: createdValue, label: createdValue }]);
        return true;
    }, [val]);

    useEffect(() => {
        setEffectiveOptions(options);
        setLabels(options.filter(o => value.includes(o.value)));
    }, [options, value]);

    useEffect(() => {
        setFilteredOptions(effectiveOptions
            .filter(o => o.label) // фильтруем пустые строки
            .filter(o => !filter || o.label?.toLowerCase().includes(filter.toLowerCase())));
    }, [filter, effectiveOptions]);

    useEffect(() => {
        if (!isExpand) {
            setFilter('');
        } else {
            inputRef.current?.focus();
        }
    }, [isExpand]);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setIsExpand);

    useEffect(() => {
        setVal(value);
        // setValue && setValue(value);
        // setLabels(effectiveOptions.filter(o => value.includes(o.value)));
        // eslint-disable-next-line
    }, [value]);

    return (
        <>
            <div className={(className || 'form__item') + (editable ? ' content-add__collection-select' : '')} onKeyDown={(e) => {
                e.key === 'Escape' && setIsExpand(false)
            }} data-test-id={`${dataTestId}_dropdown`}>
                {label && <label htmlFor="" className="form__label">{label}</label>}
                <div
                    ref={wrapperRef}
                    className={'comboInput combo-select _multiple' + (minimized ? ' _min' : '') + (isExpand ? ' _active' : '') + (val.length > 0 ? ' _selected' : '') + (top ? ' _top' : '')}
                    onClick={(e) => {
                        if (disabled) return;
                        if (e.target === wrapperRef.current) {
                            setIsExpand(!isExpand);
                        } else if (!isExpand) {
                            setIsExpand(true);
                        }
                    }}
                >
                    <span className="comboInput__label">
                        {placeholder}
                    </span>
                    <span className="comboInput__icon">
                        <ArrowDown />
                    </span>
                    <div className="comboInput__list">
                        {filteredOptions.map(option => {
                            return <div
                                key={option.value}
                                className={'comboInput__list-item' + (val.includes(option.value) ? ' _active' : '')}
                                onClick={() => {
                                    if (disabled) return;
                                    let tmp: any[] = [];
                                    if (!val.includes(option.value)) {
                                        tmp = [...val, option.value];
                                        setVal(tmp);
                                    } else {
                                        tmp = val.filter(v => v !== option.value);
                                        setVal(tmp);
                                    }
                                    setValue && setValue(tmp);
                                    const selectedOptions = effectiveOptions.filter(o => tmp.includes(o.value));
                                    onChange && onChange(selectedOptions);
                                    setLabels(selectedOptions);
                                }}
                            >
                                <span className="comboInput__list-item-icon">
                                    <Checked />
                                </span>
                                {option.label}
                                {deletable && !option.sys && <span className="comboInput__list-item-remove" onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete ?
                                        onDelete(option.value)
                                        :
                                        onDeleteLocal(option.value);
                                }}>Удалить</span>}
                            </div>
                        })}
                    </div>
                    <div className="comboInput__selected">
                        {(minimized && labels.length > 2) ?
                            <div className="comboInput__selected-item">
                                {labels.length}
                            </div>
                            :
                            labels.map(l => {
                                return <div key={l.value} className="comboInput__selected-item" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (disabled) return;
                                    const tmp = val.filter(v => v !== l.value);
                                    setVal(tmp);
                                    setValue && setValue(tmp);
                                    // setLabels(effectiveOptions.filter(o => tmp.includes(o.value)));
                                    const selectedOptions = effectiveOptions.filter(o => tmp.includes(o.value));
                                    onChange && onChange(selectedOptions);
                                    setLabels(selectedOptions);
                                }}>
                                    {l.label}
                                    <Close />
                                </div>
                            })}
                        <div className="comboInput__search">
                            <input ref={inputRef} value={filter} type="text" size={1} onChange={(e) => setFilter(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            {editable &&
                <div data-test-id={`${dataTestId}_add`} className="content-add__collection-create">
                    {activeCreate ?
                        <div className="content-add__collection-body" style={{ display: 'block', borderTop: '1px solid var(--c-bd-sep)', marginTop: '30px', paddingTop: '17px' }}>
                            <h3 className="form__section-heading">{createHeader || 'Создайте новый элемент'}</h3>
                            <p className="form__item">
                                <input ref={refNew} value={createValue} onChange={(e) => setCreateValue(e.target.value)} type="text" className="form__input" placeholder={createPlaceholder || 'Введите название нового элемента'} />
                            </p>
                            <p className="content-add__collection-cta">
                                <span className="btn _min content-add__collection-cta-create" onClick={() => {
                                    const rc = (onCreate ?
                                        onCreate(createValue)
                                        :
                                        onCreateLocal(createValue));
                                    if (rc) {
                                        setActiveCreate(false);
                                        setCreateValue('');
                                    } else {
                                        refNew.current?.focus();
                                    }
                                }}><Trans>Create</Trans></span>
                                <span className="content-add__collection-cta-cancel" onClick={() => setActiveCreate(false)}><Trans>Cancel</Trans></span>
                            </p>
                        </div>
                        :
                        <div className="content-add__collection-handler">
                            <span className="content-add__collection-handler-trigger" onClick={() => setActiveCreate(true)}>+ <Trans>Create</Trans></span>
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default MultiSelect;
