/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosProgressEvent } from 'axios';
import { UploadProcesser } from '../components/downloads/UploadProcesser';
import { commonApi, OffsetLimit, Profile, StatusPagesResponse, StatusResponse } from './api';
import { AccessRequestStatus, UserRequest } from './users';
import { HISTORY_ROLE, ROLE } from '../components/common';


export type CONTENT_FIELD =
    'title' |
    'title_eng' |
    'unit_type' |
    'qualities' |
    'collections' |
    'startyear' |
    'genres' |
    'languages' |
    'subtitles' |
    'countries' |
    'description' |
    'techinfo' |
    'links' |
    'tags' |
    'covers' |
    'trailers' |
    'mediafiles' |
    'rights_area' |
    'rights_cat' |
    'unit' |
    'view_accesslist' |
    'view_access' |
    'download_access' |
    'download_accesslist';

export enum PERMISSIONS {
    CHANGE = 'change',
    DELETE = 'delete',
    VIEW = 'view',
    DOWNLOAD = 'download',
    UPLOAD = 'upload',
    ANY = 'any',
}

export enum TYPS {
    NORMAL = 1, // "normal"
    DRAFT = 2, // "draft"
    DELETED = 4, // "deleted"
    CENSORED = 8, // "censored"
}

export enum ACCESS_TYPES {
    NOBODY = 0, // "nobody"
    ALL = 1, // "all"
    FAVORITES = 2, // "favorites"
    LIST = 3, // "list"
}

export enum UPLOAD_STATE {
    WAITING = 'waiting',
    UPLOADING = 'uploading',
    STOPPED = 'stopped',
    SUCCESS = 'success',
    COMPLETED = 'completed',
    ERROR = 'error',
    BUILDING = 'building',
    FAILED = 'failed',
}

export interface AccessControlEntry {
    permission: PERMISSIONS,
    action: 'allow' | 'deny',
    readonly: boolean,
    user?: { id: number, username: string, company: string },
    group?: { cname: string, name: string },
}

export interface AccessControlEntryRequest {
    permission: PERMISSIONS,
    action: 'allow' | 'deny',
    user?: string,
    group?: string,
}

export interface AccessResponse {
    id: number,
    user: {
        id: number,
        username: string,
        email: string,
        profile: Profile,
        groups?: string[]
    },
    content: number,
    content_title: string,
    content_covers: string[],
    content_owner: {
        id: number,
        username: string,
        email: string,
        profile: Profile
        groups?: string[] // для унификации с user
    },
    messages: [
        {
            user: string,
            message: string,
            timestamp: string,
            status: string,
        }
    ],
    status: AccessRequestStatus
}

export interface AccessSummaryResponse {
    summary: {
        all: number,
        opened: number,
        pending: number,
        granted: number,
        granted_downloaded: number,
        rejected: number,
        canceled: number
    }
}

export interface CheckMark {
    checked: boolean | null,
    updated: boolean | null,
    user: {
        id: number,
        username: string,
        email: string,
        profile: Profile,
    } | null,
}

export interface Comment {
    id: number,
    fieldname: CONTENT_FIELD,
    comment: string,
    user: {
        id: number,
        username: string,
        email: string,
        profile: Profile,
    },
    added: string
}

export interface ContentRequest {
    id?: number,
    title: string,
    title_eng: string | null,
    unit_type?: string,
    collections?: number[],
    startyear?: number,
    genres?: number[],
    countries?: string[],
    qualities?: string[],
    owner?: Pick<UserRequest, 'email' | 'username' | 'profile'>
    covers?: { name: string, url: string }[]
    permissions?: PERMISSIONS[],
    view_access: ACCESS_TYPES,
    view_accesslist: string[],
    download_access: ACCESS_TYPES,
    download_accesslist: string[],
    languages: string[],
    subtitles: string[],
    description: string,
    techinfo: string,
    links: { kinopoisk?: string, imdb?: string },
    tags: number[],
    version: number,
    typ?: TYPS,
    offers?: string[],
    autofill?: boolean,
    rights_area: number[],
    rights_cat: number[],
    moderation?: {
        sendreport?: boolean,
        comments?: {
            // title?: string | null,
            unit_type?: string | null,
            qualities?: string | null,
            collections?: string | null,
            startyear?: string | null,
            genres?: string | null,
            languages?: string | null,
            subtitles?: string | null,
            countries?: string | null,
            description?: string | null,
            techinfo?: string | null,
            links?: string | null,
            tags?: string | null,
            covers?: string | null,
            trailers?: string | null,
            rights_area?: string | null,
            rights_cat?: string | null,
            unit?: string | null,
        },
        checkmarks?: {
            unit?: boolean | null,
            title?: boolean | null,
            unit_type?: boolean | null,
            qualities?: boolean | null,
            collections?: boolean | null,
            startyear?: boolean | null,
            genres?: boolean | null,
            languages?: boolean | null,
            subtitles?: boolean | null,
            countries?: boolean | null,
            description?: boolean | null,
            techinfo?: boolean | null,
            links?: boolean | null,
            tags?: boolean | null,
            covers?: boolean | null,
            trailers?: boolean | null,
            rights_area?: boolean | null,
            rights_cat?: boolean | null,
        }
    },
}

export interface ContentUnit {
    id?: number,
    title: string,
    title_eng: string | null,
    unit_type?: { id: string, terms: { ru?: string, en?: string } },
    collections?: { id: number, name: string }[],
    startyear?: number,
    genres?: { id: string, terms: { ru?: string, en?: string } }[],
    countries?: { id: string, terms: { ru?: string, en?: string } }[],
    qualities?: { id: string, terms: { ru?: string, en?: string } }[],
    owner?: Pick<UserRequest, 'id' | 'email' | 'username' | 'profile'>
    languages: { id: string, terms: { ru?: string, en?: string } }[],
    subtitles: { id: string, terms: { ru?: string, en?: string } }[],
    covers?: { name: string, url: string }[]
    mediafiles?: MediaFile[]
    trailers?: { name: string, url: string, show_url: string }[]
    permissions?: PERMISSIONS[],
    acl: AccessControlEntry[],
    view_access: ACCESS_TYPES,
    view_accesslist?: Partial<UserRequest>[],
    download_access: ACCESS_TYPES,
    download_accesslist?: Partial<UserRequest>[],
    expire?: string,
    managed_permissions?: PermissionMap,
    typ: TYPS,
    offers: UserRequest[],
    new: boolean,
    offered: boolean,
    ar_cnt: number,
    downloads_cnt: number,
    accreq: {
        id: number,
        status: AccessRequestStatus
    } | null,
    rights_area: {id: number, title: {ru: string, en: string}}[],
    rights_cat: {id: number, title: {ru: string, en: string}}[],
    autofill: boolean,
    moderated: boolean,
    moderation: {
        comments: {
            title: Comment | null,
            unit_type: Comment | null,
            qualities: Comment | null,
            collections: Comment | null,
            startyear: Comment | null,
            genres: Comment | null,
            languages: Comment | null,
            subtitles: Comment | null,
            countries: Comment | null,
            description: Comment | null,
            techinfo: Comment | null,
            links: Comment | null,
            tags: Comment | null,
            covers: Comment | null,
            trailers: Comment | null,
            mediafiles: Comment | null,
            rights_area: Comment | null,
            rights_cat: Comment | null,
            unit: Comment | null,
        },
        checkmarks: {
            title: CheckMark,
            unit_type: CheckMark,
            qualities: CheckMark,
            collections: CheckMark,
            startyear: CheckMark,
            genres: CheckMark,
            languages: CheckMark,
            subtitles: CheckMark,
            countries: CheckMark,
            description: CheckMark,
            techinfo: CheckMark,
            links: CheckMark,
            tags: CheckMark,
            covers: CheckMark,
            trailers: CheckMark,
            mediafiles: CheckMark[],
            rights_area: CheckMark,
            rights_cat: CheckMark,
        }
    },
}

export interface ContentUnitFull extends ContentUnit {
    description: string,
    techinfo: string,
    links: { kinopoisk?: string, imdb?: string },
    tags: { id: number, name: string }[],
    version: number,
}

export interface DownloadInfo {
    id: number,
    created: string,
    file: MediaFile,
    downloaded: boolean,
    percent: number,
    avgrate: number,
    content: {
        id: number,
        title: string,
        title_eng: string,
        owner: {
            username: string,
            email: string,
            profile: Profile,
            id: number
        },
        covers: string[]
    }
}

export type HistoryAction = 'agreed' | 'comment' | 'comment_update' | 'comment_add' | 'comment_delete' | 'cover_upload' | 'cover_delete' | 'create' | 'change' | 'delete' | 'file_upload' | 'file_delete';

export interface History {
    id: number,
    action: HistoryAction,
    added: string,
    role: HISTORY_ROLE,
    username: string,
    company: string,
    attr: CONTENT_FIELD,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ovalue: any | null, // JSON
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nvalue: any | null, // JSON
    user: number,
    unit: number
}

export interface MediaFile {
    id: number,
    name: string,
    size: number,
    url: string,
    downloaded: boolean,
    transcoded?: MediaFileTranscoded[],
    attributes: {
        orbox?: Orbox,
    }
}

export interface MediaFileTranscoded {
    profile_id: number,
    size: number,
    url: string
}

export interface Orbox {
    passed: boolean,
    report: boolean,
    videocodec: {
        value: string,
        passed: boolean
    },
    resolution: {
        value: string,
        passed: boolean
    },
    check: {
        done: boolean,
        message: string
    }
}

export type PermissionMap = {
    [key in PERMISSIONS]: PermissionMapValue
}

export interface PermissionMapValue {
    users: { id?: number, username?: string, company?: string }[] | string[], groups: { id?: number, groupname?: string, company?: string }[] | string[]
}

export interface ReferenceBooks {
    genre: { id: string, terms: { ru?: string, en?: string } }[],
    language: { id: string, terms: { ru?: string, en?: string } }[],
    country: { id: string, terms: { ru?: string, en?: string } }[],
    contentunittype: { id: string, terms: { ru?: string, en?: string } }[],
    rights: {
        areas: { ru?: string, en?: string }[],
        categories: { ru?: string, en?: string }[]
    }
    quality: { id: string, terms: { ru?: string, en?: string } }[]
    subtitle: { id: string, terms: { ru?: string, en?: string } }[]
}

export interface ExtendableListItem {
    id: number,
    sys: boolean,
    rank: number,
    title: {
        ru: string,
        en: string
    }
}

export interface TotalAccessStatus {
    all: number,
    opened: number,
    pending: number,
    granted: number,
    granted_downloaded: number,
    rejected: number,
    canceled: number
}

export interface UsedBooks extends ReferenceBooks {
    collections: string[],
    years: number[],
    tags: string[]
}

export interface UserBooks extends ReferenceBooks {
    collections: string[],
    years: number[],
    tags: string[]
}

export interface UploadResponse {
    src?: File
    uuid: string,
    unit_id: number,
    content?: {
        id: number,
        title: string,
        title_eng: string,
        owner: Profile,
        covers: string[]
    }
    name: string,
    created?: string,
    updated?: string,
    state: UPLOAD_STATE,
    total_size: number,
    total_chunks: number,
    uploaded_chunks_size: number,
    uploaded_chunks_count: number,
    missed_chunks?: number[][],
    cat?: number,
}

export interface UploadUnitResponse extends UploadResponse {
    orig_name: string,
    missed_chunks: number[][],
    uploaded_size: number,
    uploaded_chunks: number,
    progress: number
}

export interface UnitUpload extends UploadResponse {
    url?: string
    src?: File
    progress: number
    errors: number
    uploadProcesser?: UploadProcesser
    missed_chunks: number[][],
}

export const contentApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getContentList: builder.query<StatusPagesResponse<ContentUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                // return `be/api/content/?offset=${params.offset}&limit=${params.limit}`
                return `be/api/content/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getContentOffers: builder.query<StatusPagesResponse<ContentUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/content/offers/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            providesTags: () => [{ type: 'Content' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getNewContent: builder.query<StatusPagesResponse<ContentUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/content/new/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                currentCache.results.next = newItems.results.next;
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getCheckedContent: builder.query<StatusPagesResponse<ContentUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/content/moderated/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                currentCache.results.next = newItems.results.next;
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getUserBook: builder.query<StatusResponse<UserBooks>, void>({
            query: () => 'be/api/content/user_books/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        getUsedBook: builder.query<StatusResponse<UsedBooks>, void>({
            query: () => 'be/api/content/used_books/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        getReferenceBook: builder.query<StatusResponse<ReferenceBooks>, void>({
            query: () => 'be/api/content/reference_books/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        getReferenceBookTags: builder.query<StatusResponse<string[]>, void>({
            query: () => 'be/api/content/reference_books/tags/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        getContentUnitTags: builder.query<StatusPagesResponse<{ id: number, name: string }>, number>({
            query: (id: number) => `be/api/content/${id}/tags/`,
            // providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),
        getContentUnitCollections: builder.query<StatusPagesResponse<{ id: number, name: string }>, number>({
            query: (id: number) => `be/api/content/${id}/collections/`,
            // providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),
        getUserTags: builder.query<StatusPagesResponse<{ id: number, name: string }>, void>({
            query: () => 'be/api/content/tags/',
            keepUnusedDataFor: 3,
        }),
        createContentTags: builder.mutation<StatusResponse<{ id: number, name: string }>, {id: number, name: string}>({
            query: (data) => ({
                url: `be/api/content/${data.id}/tags/`,
                method: 'POST',
                body: { name: data.name }
            })
        }),
        deleteContentTags: builder.mutation<void, {id: number, tid: number}>({
            query: (data) => ({
                url: `be/api/content/${data.id}/tags/${data.tid}/`,
                method: 'DELETE',
            })
        }),
        createUserTags: builder.mutation<StatusResponse<{ id: number, name: string }>, string>({
            query: (name: string) => ({
                url: 'be/api/content/tags/',
                method: 'POST',
                body: { name }
            })
        }),
        deleteUserTags: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/content/tags/${id}/`,
                method: 'DELETE',
            })
        }),
        createContentCollections: builder.mutation<StatusResponse<{ id: number, name: string }>, {id: number, name: string}>({
            query: (data) => ({
                url: `be/api/content/${data.id}/collections/`,
                method: 'POST',
                body: { name: data.name }
            })
        }),
        deleteContentCollections: builder.mutation<void, {id: number, tid: number}>({
            query: (data) => ({
                url: `be/api/content/${data.id}/collections/${data.tid}/`,
                method: 'DELETE',
            })
        }),
        getUserCollections: builder.query<StatusPagesResponse<{ id: number, name: string }>, void>({
            query: () => 'be/api/content/collections/',
            keepUnusedDataFor: 3,
        }),
        createUserCollections: builder.mutation<StatusResponse<{ id: number, name: string }>, string>({
            query: (name: string) => ({
                url: 'be/api/content/collections/',
                method: 'POST',
                body: { name }
            })
        }),
        deleteUserCollections: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/content/collections/${id}/`,
                method: 'DELETE',
            })
        }),
        getContentUnit: builder.query<StatusResponse<ContentUnitFull>, number>({
            query: (id: number) => `be/api/content/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),
        getRightsAreas: builder.query<StatusPagesResponse<ExtendableListItem>, void>({
            query: () => '/be/api/content/rights/areas/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        getContentRightsAreas: builder.query<StatusPagesResponse<ExtendableListItem>, number>({
            query: (id) => `/be/api/content/${id}/rights/areas/`,
            // providesTags: () => [{ type: 'Content' }],
        }),
        createRightsAreas: builder.mutation<StatusResponse<ExtendableListItem>, { id?: number, rank?: number, title: string }>({
            query: (data) => ({
                url: data.id ? `/be/api/content/${data.id}/rights/areas/` : '/be/api/content/rights/areas/',
                method: 'POST',
                body: { title: data.title, rank: data.rank }
            })
        }),
        deleteRightsAreas: builder.mutation<void, { id?: number, rid: number }>({
            query: (data) => ({
                url: data.id ? `/be/api/content/${data.id}/rights/areas/${data.rid}/` : `/be/api/content/rights/areas/${data.rid}/`,
                method: 'DELETE',
            })
        }),
        getRightsCategories: builder.query<StatusPagesResponse<ExtendableListItem>, void>({
            query: () => '/be/api/content/rights/categories/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        getContentRightsCategories: builder.query<StatusPagesResponse<ExtendableListItem>, number>({
            query: (id) => `/be/api/content/${id}/rights/categories/`,
            // providesTags: () => [{ type: 'Content' }],
        }),
        createRightsCategories: builder.mutation<StatusResponse<ExtendableListItem>, { id?: number, rank?: number, title: string }>({
            query: (data) => ({
                url: data.id ? `/be/api/content/${data.id}/rights/categories/` : '/be/api/content/rights/categories/',
                method: 'POST',
                body: { title: data.title, rank: data.rank }
            })
        }),
        deleteRightsCategories: builder.mutation<void, { id?: number, rid: number }>({
            query: (data) => ({
                url: data.id ? `/be/api/content/${data.id}/rights/categories/${data.rid}/` : `/be/api/content/rights/categories/${data.rid}/`,
                method: 'DELETE',
            })
        }),
        // История изменений единицы контента
        getContentHistory: builder.query<StatusPagesResponse<History>, number>({
            query: (id: number) => `/be/api/content/${id}/history/`,
        }),
        // /be/api/content/<id>/media/<mediaid>/attributes/orbox/passed/  - [GET] Результаты выполнения тестов Orbox
        getOrboxPassed: builder.query<StatusPagesResponse<History>, { id: number, mediaid: number }>({
            query: (data) => `/be/api/content/${data.id}/media/${data.mediaid}/attributes/orbox/passed/`,
        }),
        // /be/api/content/<id>/media/<mediaid>/attributes/orbox/report/  - [GET] Отчет Orbox
        getOrboxReport: builder.query<StatusPagesResponse<History>, { id: number, mediaid: number }>({
            query: (data) => `/be/api/content/${data.id}/media/${data.mediaid}/attributes/orbox/report/`,
        }),
        // # Модерирование
        // /be/api/content/<id>/moderation/comments/           - [CUD] Создание/удаление/изменение/список комментарием для единицы контента
        getContentComents: builder.query<StatusPagesResponse<History>, number>({
            query: (id: number) => `/be/api/content/${id}/moderation/comments/`,
        }),
        createContentComment: builder.mutation<void, { id: number, fieldname: string, comment: string }>({
            query: (data) => ({
                url: `/be/api/content/${data.id}/moderation/comments/`,
                method: 'POST',
                body: { fieldname: data.fieldname, comment: data.comment },
            }),
        }),
        updateContentComment: builder.mutation<void, { id: number, commentid: number, comment: string }>({
            query: (data) => ({
                url: `/be/api/content/${data.id}/moderation/comments/${data.commentid}/`,
                method: 'POST',
                body: { comment: data.comment },
            }),
        }),
        deleteContentComment: builder.mutation<void, { id: number, commentid: number }>({
            query: (data) => ({
                url: `/be/api/content/${data.id}/moderation/comments/${data.commentid}/`,
                method: 'DELETE',
            }),
        }),
        // /be/api/content/<id>/moderation/comments/fields     - Список доступных для комментирования полей
        getContentComentsFields: builder.query<StatusResponse<string[]>, number>({
            query: (id: number) => `/be/api/content/${id}/moderation/comments/fields/`,
        }),
        // /be/api/content/<id>/moderation/checkmarks/         - [CU] Список/редактирование галочек модерирования единицы контента
        getContentCheckmarks: builder.query<StatusResponse<{ [key: string]: CheckMark }>, number>({
            query: (id: number) => `/be/api/content/${id}/moderation/checkmarks/`,
        }),
        setContentCheckmarks: builder.mutation<void, { id: number, checks: { [key: string]: boolean } }>({
            query: (data) => ({
                url: `/be/api/content/${data.id}/moderation/checkmarks/`,
                method: 'POST',
                body: data.checks,
            })
        }),
        // /be/api/content/<id>/moderation/checkmarks/fields/  - Список доступных для модерирования полей
        getContentCheckmarksFields: builder.query<StatusResponse<string[]>, number>({
            query: (id: number) => `/be/api/content/${id}/moderation/checkmarks/fields/`,
        }),
        sendContent: builder.mutation<void, { id: number, users: string[] }>({
            query: ({ id, users }) => ({
                url: `be/api/content/${id}/send/`,
                method: 'POST',
                body: { users }
            }),
        }),
        getContentUnitMedia: builder.query<StatusResponse<Pick<ContentUnitFull, 'mediafiles' | 'version' | 'id'>>, number>({
            query: (id: number) => `be/api/content/${id}/`,
            transformResponse: (response: StatusResponse<Pick<ContentUnitFull, 'mediafiles' | 'version' | 'id'>>, _meta, _arg) => {
                const onlyMedia = response.results?.mediafiles;
                const version = response.results?.version;
                const id = response.results?.id;
                const result = { status: response.status, results: { mediafiles: onlyMedia, version, id } };
                return result;
            },
            providesTags: (result, error, id) => [{ type: 'Media', id }],
        }),
        createContentUnit: builder.mutation<StatusResponse<ContentUnitFull>, Pick<ContentRequest, 'title' | 'genres' | 'languages' | 'subtitles' | 'countries'>>({
            query: (data: ContentRequest) => ({
                url: 'be/api/content/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Content' }]
        }),
        createDraftUnit: builder.mutation<StatusResponse<ContentUnitFull>, string | undefined>({
            query: (title) => ({
                url: 'be/api/content/',
                method: 'POST',
                body: {
                    title: title || '',
                    title_eng: '',
                    languages: [],
                    subtitles: [],
                    countries: [],
                    description: '',
                    techinfo: '',
                    tags: [],
                    links: {},
                    version: 0,
                    acl: [],
                    view_access: ACCESS_TYPES.NOBODY,
                    view_accesslist: [],
                    download_access: ACCESS_TYPES.NOBODY,
                    download_accesslist: [],
                    typ: TYPS.DRAFT,
                }
            }),
        }),
        makeCensoredUnit: builder.mutation<StatusResponse<ContentUnitFull>, number>({
            query: (id: number) => ({
                url: `be/api/content/${id}/`,
                method: 'PATCH',
                body: {
                    typ: TYPS.CENSORED,
                }
            }),
        }),
        unmakeCensoredUnit: builder.mutation<StatusResponse<ContentUnitFull>, number>({
            query: (id: number) => ({
                url: `be/api/content/${id}/`,
                method: 'PATCH',
                body: {
                    typ: TYPS.NORMAL,
                }
            }),
        }),
        editContentUnit: builder.mutation<StatusResponse<ContentUnitFull>, Partial<ContentRequest>>({
            query: (data: ContentRequest) => ({
                url: `be/api/content/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Content' }, { type: 'Content', id: result?.results.id }]
        }),
        deleteContentUnit: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/content/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Content' }]
        }),
        getUploads: builder.query<StatusResponse<{ data: UploadResponse[] }>, void>({
            query: () => 'be/api/content/uploads/',
            providesTags: (result, error, id) => [{ type: 'Content' }, { type: 'Upload' }],
        }),
        getUnitUploads: builder.query<StatusResponse<{ data: UploadResponse[] }>, number>({
            query: (id: number) => `be/api/content/${id}/upload/?cat=1`,
            providesTags: (result, error, id) => [{ type: 'Content' }, { type: 'Upload', id }],
            transformResponse: (response: StatusResponse<{ data: UploadResponse[] }>, meta, arg) => {
                return { status: response.status, results: { data: response.results.data.filter(u => u.cat === 1) } };
            },
        }),
        getUnitTrailerUploads: builder.query<StatusResponse<{ data: UploadResponse[] }>, number>({
            query: (id: number) => `be/api/content/${id}/upload/?cat=3`,
            transformResponse: (response: StatusResponse<{ data: UploadResponse[] }>, meta, arg) => {
                return { status: response.status, results: { data: response.results.data.filter(u => u.cat === 3) } };
            },
        }),
        getUnitUpload: builder.query<StatusResponse<UploadUnitResponse>, { id: number, uuid: string }>({
            query: (args) => `be/api/content/${args.id}/upload/${args.uuid}/`,
            providesTags: (result, error, args) => [{ type: 'Content' }, { type: 'Upload', id: args.id }],
            transformResponse: (response: StatusResponse<UploadUnitResponse>, meta, arg) => {
                const result = { ...response.results, progress: response.results.uploaded_chunks_count / response.results.total_chunks * 100 };
                return { status: response.status, results: result };
            },
        }),
        deleteUnitUpload: builder.mutation<StatusResponse<UploadUnitResponse>, { id: number, uuid: string }>({
            query: (args) => ({
                url: `be/api/content/${args.id}/upload/${args.uuid}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Upload' }]
        }),
        getMediaHeader: builder.mutation<void, string>({
            query: (url) => ({
                url: url,
                method: 'HEAD',
                credentials: 'include',
            }),
        }),
        uploadContentMediaChunk: builder.mutation<StatusResponse<UploadResponse>, { id: number, url: string, data: Buffer, signal?: AbortSignal }>({
            query: (args) => ({
                url: args.url,
                method: 'POST',
                body: args.data,
                headers: { 'Content-Type': 'application/octet-stream' },
                signal: args.signal,
            }),
        }),
        deleteContentMedia: builder.mutation<void, string>({
            query: (url) => ({
                url: url,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Media' }]
        }),
        createContentTrailer: builder.mutation<void, { id: number, file: File, onProgress?: (e: AxiosProgressEvent) => void }>({
            query(data) {
                const formData = new FormData();
                formData.append('file', data.file);
                return {
                    url: `be/api/content/${data.id}/trailer/`,
                    method: 'POST',
                    body: formData,
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        data.onProgress && data.onProgress(progressEvent)
                    }
                };
            },
        }),
        deleteContentTrailer: builder.mutation<void, string>({
            query: (url) => ({
                url: url,
                method: 'DELETE',
            }),
        }),
        createContentCover: builder.mutation<void, { id: number, file: File, onProgress?: (e: AxiosProgressEvent) => void }>({
            query(data) {
                const formData = new FormData();
                formData.append('file', data.file);
                return {
                    url: `be/api/content/${data.id}/cover/`,
                    method: 'POST',
                    body: formData,
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        data.onProgress && data.onProgress(progressEvent)
                    },
                };
            },
        }),
        deleteContentCover: builder.mutation<void, string>({
            query: (url) => ({
                url: url,
                method: 'DELETE',
            }),
        }),
        getMetaData: builder.mutation<string, { taskid?: string, id: number }>({
            query: (data) => ({
                url: `be/api/content/${data.id}/metadata/`,
                method: 'POST',
                body: { taskid: data.taskid, inline: false },
                responseType: 'blob',
                responseDataHandler: async (blobData: BlobPart) => {
                    const blob = new Blob([blobData]);
                    return URL.createObjectURL(blob);
                },
            }),
        }),
        getDowloadInfo: builder.query<StatusResponse<DownloadInfo[]>, void>({
            query: () => 'be/api/content/downloads/',
            providesTags: () => [{ type: 'Content' }],
        }),
        getContentDownloadInfo: builder.query<StatusResponse<DownloadInfo[]>, number>({
            query: (id: number) => `be/api/content/${id}/downloads/`,
            providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),
        accessRequest: builder.mutation<StatusResponse<AccessResponse>, { id: number, message?: string }>({
            query: (data) => ({
                url: `be/api/content/${data.id}/access/request/`,
                method: 'POST',
                data: { message: data.message || 'Empty message' }
            }),
            invalidatesTags: (result, error, data) => [{ type: 'Access' }, { type: 'Content' }, { type: 'Content', id: data.id }]
        }),
        getAccessRequest: builder.query<StatusResponse<AccessResponse>, number>({
            query: (id: number) => `be/api/content/access/requests/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Access', id }],
        }),
        getAccessRequests: builder.query<StatusPagesResponse<AccessResponse> & { results: { totals: TotalAccessStatus } }, OffsetLimit>({
            query: (params: OffsetLimit) => `be/api/content/access/requests/?${Object.keys(params).filter(k => params[k]).map(k => k + '=' + params[k]).join('&')}`,
            providesTags: (result, error, id) => [{ type: 'Access' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getAccessRequestsSummary: builder.query<StatusResponse<AccessSummaryResponse>, void>({
            query: () => 'be/api/content/access/requests/summary/',
            providesTags: (result, error, id) => [{ type: 'Access' }],
        }),
        accessRequestsGrant: builder.mutation<StatusResponse<AccessResponse>, number>({
            query: (id) => ({
                url: `be/api/content/access/requests/${id}/grant/`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Access' }, { type: 'Access', id }]
        }),
        accessRequestsDelete: builder.mutation<void, number>({
            query: (id) => ({
                url: `be/api/content/access/requests/${id}/`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Access' }, { type: 'Access', id }]
        }),
        accessRequestsReject: builder.mutation<StatusResponse<AccessResponse>, number>({
            query: (id) => ({
                url: `be/api/content/access/requests/${id}/reject/`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Access' }, { type: 'Access', id }]
        }),
        accessRequestsPending: builder.mutation<StatusResponse<AccessResponse>, number>({
            query: (id) => ({
                url: `be/api/content/access/requests/${id}/pending/`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Access' }, { type: 'Access', id }]
        }),
        accessRequestsMessage: builder.mutation<StatusResponse<AccessResponse>, { id: number, message: string }>({
            query: ({ id, message }) => ({
                url: `be/api/content/access/requests/${id}/message/`,
                method: 'POST',
                data: { message },
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Access' }, { type: 'Access', id }]
        }),
        getCover: builder.mutation<string, string>({
            query: (url) => ({
                url: url,
                responseType: 'blob',
                responseDataHandler: async (data: BlobPart) => {
                    const blob = new Blob([data], { type: 'image/jpg' })
                    return URL.createObjectURL(blob);
                },
                cache: 'no-cache',
            }),
        }),
    }),
})

export const { useGetContentListQuery, useDeleteContentUnitMutation, useCreateContentUnitMutation,
    useGetContentUnitQuery, useLazyGetContentUnitQuery, useEditContentUnitMutation, useGetReferenceBookQuery,
    useDeleteContentCoverMutation, useCreateContentCoverMutation, useAccessRequestMutation,
    useGetAccessRequestsQuery, useAccessRequestsGrantMutation, useAccessRequestsRejectMutation, useGetCoverMutation,
    useGetReferenceBookTagsQuery, useAccessRequestsDeleteMutation,
    useCreateContentTrailerMutation, useDeleteContentTrailerMutation, useGetMetaDataMutation,
    useGetAccessRequestQuery, useDeleteContentMediaMutation, useGetUnitUploadsQuery, useUploadContentMediaChunkMutation, useGetUploadsQuery,
    useGetUnitUploadQuery, useLazyGetUnitUploadQuery, useDeleteUnitUploadMutation, useLazyGetUnitUploadsQuery,
    useGetContentUnitMediaQuery, useLazyGetContentUnitMediaQuery, useCreateDraftUnitMutation,
    useSendContentMutation, useGetContentOffersQuery, useGetNewContentQuery, useAccessRequestsMessageMutation, useAccessRequestsPendingMutation,
    useLazyGetNewContentQuery, useLazyGetContentOffersQuery, useLazyGetContentListQuery, useLazyGetAccessRequestsQuery,
    useGetContentUnitCollectionsQuery, useGetContentUnitTagsQuery, useGetUsedBookQuery, useGetUserBookQuery, useGetUserCollectionsQuery,
    useGetUserTagsQuery, useCreateUserCollectionsMutation, useCreateUserTagsMutation, useDeleteUserCollectionsMutation, useDeleteUserTagsMutation,
    useLazyGetUserCollectionsQuery, useLazyGetUserTagsQuery, useGetAccessRequestsSummaryQuery, useGetMediaHeaderMutation,
    useGetDowloadInfoQuery, useGetContentDownloadInfoQuery, useGetUnitTrailerUploadsQuery, useGetXlsMutation, useGetDashboardQuery,
    useGetSummaryQuery, useLazyGetUnitTrailerUploadsQuery, useGetRightsAreasQuery, useGetRightsCategoriesQuery, useMakeCensoredUnitMutation,
    useUnmakeCensoredUnitMutation, useGetContentHistoryQuery, useGetCheckedContentQuery, useLazyGetCheckedContentQuery,
    useGetContentRightsAreasQuery, useGetContentRightsCategoriesQuery, useCreateRightsAreasMutation, useCreateRightsCategoriesMutation, 
    useCreateContentCommentMutation, useDeleteContentCommentMutation, useDeleteRightsAreasMutation, useDeleteRightsCategoriesMutation,
    useCreateContentTagsMutation, useDeleteContentTagsMutation, useCreateContentCollectionsMutation, useDeleteContentCollectionsMutation,
} = contentApi;
