import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
// import ContentListPage from './pages/ContentListPage';
import PrivateOutlet from './components/mplat/PrivateOutlet';
// import ProfilePage from './pages/ProfilePage';
// import RequestListPage from './pages/RequestListPage';
import ResetFormPage from './pages/ResetFormPage';
// import UserList from './pages/UserListPage';
import './langs/i18n'
// import ContentEditorPage from './pages/ContentEditorPage';
// import ContentViewerPage from './pages/ContentViewerPage';
// import NotificationList from './pages/NotificationListPage';
// import HelpPage from './pages/HelpPage';
// import ConsumerViewer from './components/ConsumerViewer';
// import AccessViewer from './components/AccessViewer';
import { setCredentials, setRole } from './services/api';
import { UnitUpload, UPLOAD_STATE } from './services/content';
import { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { UploadFilesContext, UploadFilesContextType } from './components/downloads/UploaderContext';
import { useDispatch } from 'react-redux';
// import ContactsPage from './pages/ContactsPage';
import NotFoundPage from './pages/NotFoundPage';
// import OrdersListPage from './pages/OrdersListPage';
// import SubscribersPage from './pages/SubscribersPage';
// import DashboardPage from './pages/DashboardPage';
// import ToolsPage from './pages/ToolsPage';
import { Spinner } from './components/mplat/Spinner';
import IndexPage from './pages/IndexPage';


const ContactsPage = React.lazy(() => import('./pages/ContactsPage'));
const HelpPage = React.lazy(() => import('./pages/HelpPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const OrdersListPage = React.lazy(() => import('./pages/OrdersListPage'));
const AccessViewerPage = React.lazy(() => import('./pages/AccessViewerPage'));
const UserListPage = React.lazy(() => import('./pages/UserListPage'));
const SubscribersPage = React.lazy(() => import('./pages/SubscribersPage'));
const ConsumerViewerPage = React.lazy(() => import('./pages/ConsumerViewerPage'));
const ContentViewerPage = React.lazy(() => import('./pages/ContentViewerPage'));
const ContentEditorPage = React.lazy(() => import('./pages/ContentEditorPage'));
const ContentListPage = React.lazy(() => import('./pages/ContentListPage'));
const ContentHistoryPage = React.lazy(() => import('./pages/ContentHistoryPage'));
const NotificationListPage = React.lazy(() => import('./pages/NotificationListPage'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'));
const RequestListPage = React.lazy(() => import('./pages/RequestListPage'));
const ToolsPage = React.lazy(() => import('./pages/ToolsPage'));

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        delete localStorage.user;
        delete localStorage.suUser;
        delete localStorage.suRefresh;
        delete localStorage.refreshToken;
        delete localStorage.accessToken;
        dispatch(setCredentials({ user: '', accessToken: '', refreshToken: '' }));
        dispatch(setRole(''));
        navigate('/', { replace: true });
    }, [navigate, dispatch]);

    return <>Выходим...</>;
};

function AppMain() {
    const [uploadFiles, setUploadingFiles] = useState<{ [key: string]: UnitUpload }>({});
    const { config } = useContext<UploadFilesContextType>(UploadFilesContext);
    const dispatch = useDispatch();

    const onRefreshChange = useCallback((ev: StorageEvent) => {
        if (ev.key === 'refreshToken' && ev.oldValue !== ev.newValue) {
            dispatch(setCredentials({refreshToken: ev.newValue || undefined}));
        }
        if (ev.key === 'accessToken' && ev.oldValue !== ev.newValue) {
            dispatch(setCredentials({accessToken: ev.newValue || undefined}));
        }
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener('storage', onRefreshChange);

        return () => {
            window.removeEventListener('storage', onRefreshChange);
        }
    }, [onRefreshChange]);

    const onClose = useCallback((ev: BeforeUnloadEvent) => {
        if (Object.keys(uploadFiles).length > 0 && Object.values(uploadFiles).find((u) => u.state === UPLOAD_STATE.UPLOADING) && Object.values(uploadFiles).find((u) => !!u.src)) {
            ev.preventDefault();
            ev.returnValue = 'Are you sure you want to close?';
            return 'Are you sure you want to close?';
        }
    }, [uploadFiles]);

    useEffect(() => {
        window.addEventListener('beforeunload', onClose);

        return () => {
            window.removeEventListener('beforeunload', onClose);
        }
    }, [onClose]);

    return <main className="main">
        <UploadFilesContext.Provider value={{ uploadFiles, setUploadingFiles, config }}>
            <Routes>
                <Route path="/login" element={<IndexPage />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/reset-confirm" element={<ResetFormPage reset={true} />} />
                <Route path="/activate-account" element={<ResetFormPage />} />
                <Route path="/contacts" element={<Suspense fallback={<Spinner />}><ContactsPage /></Suspense>} />
                <Route path="/help" element={<Suspense fallback={<Spinner />}><HelpPage /></Suspense>} />
                <Route path="/" element={<PrivateOutlet />}>
                    <Route index element={<Suspense fallback={<Spinner />}><DashboardPage /></Suspense>} />
                    <Route path="/access" element={<Suspense fallback={<Spinner />}><OrdersListPage /></Suspense>} />
                    <Route path="/access/:id" element={<Suspense fallback={<Spinner />}><AccessViewerPage /></Suspense>} />
                    <Route path="/admin" element={<Suspense fallback={<Spinner />}><UserListPage /></Suspense>} />
                    <Route path="/subscribers" element={<Suspense fallback={<Spinner />}><SubscribersPage /></Suspense>} />
                    <Route path="/consumers" element={<Suspense fallback={<Spinner />}><SubscribersPage /></Suspense>} />
                    <Route path="/consumers/:id" element={<Suspense fallback={<Spinner />}><ConsumerViewerPage /></Suspense>} />
                    <Route path="/content/view/:id" element={<Suspense fallback={<Spinner />}><ContentViewerPage /></Suspense>} />
                    <Route path="/content/history/:id" element={<Suspense fallback={<Spinner />}><ContentHistoryPage /></Suspense>} />
                    <Route path="/content/:id" element={<Suspense fallback={<Spinner />}><ContentEditorPage /></Suspense>} />
                    <Route path="/content" element={<Suspense fallback={<Spinner />}><ContentListPage /></Suspense>} />
                    <Route path="/showcase" element={<Suspense fallback={<Spinner />}><ContentListPage /></Suspense>} />
                    {/* <Route path="/edit-profile" element={<ProfileEditor userId={NaN} />} /> */}
                    <Route path="/notifications" element={<Suspense fallback={<Spinner />}><NotificationListPage /></Suspense>} />
                    <Route path="/profile" element={<Suspense fallback={<Spinner />}><ProfilePage /></Suspense>} />
                    <Route path="/requests" element={<Suspense fallback={<Spinner />}><RequestListPage /></Suspense>} />
                    <Route path="/tools" element={<Suspense fallback={<Spinner />}><ToolsPage /></Suspense>} />
                    <Route path="/downloads" element={null} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </UploadFilesContext.Provider>
    </main>
}

export default AppMain;