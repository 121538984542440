import { commonApi, OffsetLimit, Profile, StatusPagesResponse } from './api';
import { MediaFile } from './content';


export enum TRANSCODING_STATE_ID {
    CREATED = 1, // "created")
    PROCESSED = 2, // "processed")
    CONVERTED = 3, // "converted")
    PACKED = 4, // "packed")
    FINISHED = 5, // "finished")
    FAILED = 6, // "failed")
}

export enum TRANSCODING_STATE {
    CREATED = 'created',
    PROCESSED = 'processed',
    CONVERTED = 'converted',
    PACKED = 'packed',
    FINISHED = 'finished',
    FAILED = 'failed',
}

export interface TranscodingOutput {
    ac: number,
    fit: boolean,
    level: number,
    width: number,
    height: number,
    preset: string,
    bufsize: string,
    maxrate: string,
    minrate: string,
    profile: string,
    filename: string,
    muxdelay: number,
    one_pass: boolean,
    frame_rate: boolean,
    thumbnails?: {
        exact: boolean,
        times: string[],
        presets: {
            big: string,
            small: string
        }
    },
    'x264-params': string,
    audio_bitrate: number,
    video_bitrate: number,
    full_processing?: boolean,
}

export interface TranscodingOutputStream {
    type: string,
    streams: Array<{ path: string, bandwidth: number }>
    filename: string
}

export interface TranscodingProlile {
    id: number,
    name: string,
    data: {
        outputs: Array<TranscodingOutput | TranscodingOutputStream>
    },
    updated: string
}

export interface TranscodingTaskResponse {
    id: number,
    created: string,
    content: {
        id: number,
        title: string,
        title_eng: string,
        owner: Profile,
        covers: string[]
    },
    mediafile: MediaFile,
    transcoded: MediaFile,
    profile: {
        id: number,
        name: string
    },
    state: TRANSCODING_STATE_ID,
    statename: TRANSCODING_STATE,
    progress: number,
    error: string | null
}

export const transcodeApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        // это должны скачивать по прямой ссылке
        // getTranscodeArchive: builder.query<void, { contentId: number, mediaId: number, transcodedId: number }>({
        //     query: ({ contentId, mediaId, transcodedId }) => `be/api/content/${contentId}/media/${mediaId}/transcoded/${transcodedId}/`,
        // }),
        createTranscodeTask: builder.mutation<TranscodingTaskResponse, { mediaUrl: string, profileId: number }>({
            query({ mediaUrl, profileId }) {
                return {
                    url: `${mediaUrl}transcode/`,
                    method: 'POST',
                    body: { profile_id: profileId }
                };
            },
        }),
        getTranscodingTasks: builder.query<StatusPagesResponse<TranscodingTaskResponse>, OffsetLimit>({
            query: (params) => `be/api/content/transcoding/requests/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
        }),
        getTranscodeProfiles: builder.query<StatusPagesResponse<TranscodingProlile>, void>({
            query: () => 'be/api/content/transcoding/profiles/',
        }),

        // /be/api/content/<id>/media/<mediaid>/transcode/     - [POST] создать задачу на транскодирование файла
        // /be/api/content/transcoding/requests/               - Список задач пользователя на транскодирование файлов
        // /be/api/content/transcoding/profiles/               - Список доступных профайлов для транскодирования
    }),
});

export const {
    useCreateTranscodeTaskMutation, useGetTranscodeProfilesQuery, useGetTranscodingTasksQuery,
} = transcodeApi;
