interface SectionProps {
    label: string,
    icon?: JSX.Element,
    hint?: string,
    children?: React.ReactNode;
}

function Section({ label, icon, hint, children }: SectionProps) {
    return (
        <section className="form__section">
            <h3 className="form__section-heading">
                {icon} {label} <span>{hint}</span>
            </h3>
            {children}
        </section>
    );
}

export default Section;