interface MainHeaderProps {
    label: string,
    icon?: JSX.Element,
    children?: React.ReactNode;
}

function MainHeader({ label, icon, children }: MainHeaderProps) {
    return <header className="main__header">
        <h1 className="main__header-heading">
            {icon} {label}
            </h1>
            {children}
    </header>
}

export default MainHeader;
