import { t } from 'i18next';
import { useAuth, useRole } from '../hooks';

export enum ROLE {
    CONSUMER = 'consumers',
    PROVIDER = 'providers',
    ADMIN = 'administrators',
    MODERATOR = 'moderators',
    UNKNOWN = 'unknown'
}

export enum HISTORY_ROLE {
    CONSUMER = 'consumer',
    PROVIDER = 'provider',
    ADMIN = 'administrator',
    MODERATOR = 'moderator',
    UNKNOWN = 'unknown'
}

export function IsConsumer() {
    const role = useRole();

    return role.role === ROLE.CONSUMER;
}

export function IsProvider() {
    const role = useRole();

    return role.role === ROLE.PROVIDER;
}

export function IsAdmin() {
    const role = useRole();

    return role.role === ROLE.ADMIN;
}

export function IsOwner(user: string) {
    const role = useRole();
    const auth = useAuth();

    return role.role === ROLE.PROVIDER && auth.user === user;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function _toString(value: any, keySeparator?: string): string {
    const sep = keySeparator || '\n';
    if (typeof value === 'number') {
        return value.toString();
    }
    if (typeof value === 'string') {
        return t(value);
    }
    if (Array.isArray(value)) {
        return value.map(v => _toString(v, sep)).join(' ');
    }
    if (typeof value === 'object') {
        return Object.keys(value).map((v) => {
            if (v === 'non_field_errors') {
                return _toString(value[v]);
            } else {
                return `${v}: ${_toString(value[v], sep)}`
            }
        }).join(sep);
    }
    return value?.toString();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractErrorDetail(e: any): string {
    if (e.data?.response?.detail) {
        return _toString(e.data.response.detail);
    }
    if (e.data?.response?.status?.detail) {
        return _toString(e.data.response.status.detail);
    }
    if (e.data?.response?.status?.msg) {
        return _toString(e.data?.response?.status?.msg);
    }
    if (e.data?.response?.message) {
        return _toString(e.data.response.message);
    }
    if (e.data?.status) {
        return _toString(e.data.status);
    }
    if (e.data?.message) {
        return _toString(e.data.message);
    }
    return e.status || 'ERROR';
}

export function formatBytes(bytes: number, decimals = 2, lang = 'ru') {
    if (!+bytes) return '0 ' + (lang === 'ru' ? 'Байт' : 'Bytes');

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizesEn = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const sizesRu = ['б', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Эб', 'Зб', 'Йб'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${lang === 'ru' ? sizesRu[i] : sizesEn[i]}`;
}
