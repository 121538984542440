/* eslint-disable @typescript-eslint/no-explicit-any */

interface RadioInputProps {
    value: any,
    items: { value: any, label: string, counter?: number }[],
    disabled?: boolean,
    onChange?: (value: any) => void
}

export function RadioInput({ value, items, onChange, disabled }: RadioInputProps) {

    return (
        <ul>
            {items.map(item => {
                return <li key={item.value}>
                    <label
                        className={'radio' + (value === item.value ? ' radio-checked' : '')}
                    >
                        <input
                            type="radio"
                            name="form-register__role"
                            // checked={value === item.value}
                            value={item.value}
                            onClick={() => {
                                if (disabled) return;
                                onChange && onChange(item.value);
                            }}
                        /> {item.label}
                        <i></i>
                    </label>
                </li>
            })}
        </ul>
    )
}