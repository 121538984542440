import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { AxiosProgressEvent } from 'axios';
import { ROLE } from '../components/common';
import { Language, OffsetLimit, Profile, StatusResponse } from './api';
import { baseQueryWithReauth } from './reauth';


export enum AccessRequestStatus {
    OPEN = 'open',
    PENDING = 'pending',
    GRANTED = 'granted',
    REJECTED = 'rejected'
}

export interface AccountRequest {
    email: string,
    roles: string[],
    profile: Partial<Profile>,
    email_verification: string,
    lang?: Language,
}

export interface ApproveRequest {
    email: string,
    roles: string[],
    profile: Partial<Profile>,
}

export interface AuthResponse {
    username: string
    access: string
    refresh: string
    suUser?: string
    suRefresh?: string
}

export interface ChangePasswordRequest {
    old_password: string,
    password: string
}

export interface ConfirmRequest {
    email: string,
    code: string
}

export interface ConfimResponse {
    email: string,
    email_used: boolean,
    email_verification: string,
    confirmed: boolean
}

export interface ConfirmTokenRequest {
    uid: string,
    token: string,
    password: string
}

export interface EmailRequest {
    email: string,
    lang: Language,
    token?: string
}

export interface RequestUserResponse {
    id: number,
    email: string,
    profile: Profile,
    roles: ROLE[],
    email_verification: string,
    last_updated: string
}

export interface TotalUserData {
    all: number,
    new: number,
    offers: number,
    favorites: number,
    blocked: number
}

export interface ValidateEmailResponse {
    email: string,
    resend_in: number,
    code_expired_in: number,
    sended: boolean
}

export interface ValidateEmailRequest {
    uid: string,
    token: string,
}

export type UserGroup = 'favorites' | 'blocked' | undefined

export interface UserRequest {
    id?: number,
    username?: string,
    email: string,
    profile: Profile,
    groups?: UserGroup[],
    roles: ROLE[],
    is_active: boolean,
}

export interface UserResponse extends UserRequest {
    date_joined: string,
    last_login: string
}

const staggeredBaseQuery = retry(baseQueryWithReauth, {
    maxRetries: 0,
})

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Users', 'UserRequests'],
    keepUnusedDataFor: 30,
    endpoints: (builder) => ({
        getUsers: builder.query<StatusResponse<UserResponse[]>, void>({
            query: () => 'be/api/user/admin/',
            providesTags: () => [{ type: 'Users' }]
        }),
        getClientsTotal: builder.query<StatusResponse<{ totals: TotalUserData }>, void>({
            query: () => 'be/api/user/list/totals/',
        }),
        getClients: builder.query<StatusResponse<{ data: Omit<UserRequest, 'roles' | 'is_active'>[], totals: TotalUserData, count: number, next: string | null, previous: string | null }>, OffsetLimit>({
            // query: () => `be/api/user/list/`,
            query: (params: OffsetLimit) => `be/api/user/list/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: () => [{ type: 'Users' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getClient: builder.query<StatusResponse<{ data: Omit<UserRequest, 'roles' | 'is_active'>[] }>, number | string>({
            query: (id: number | string) => {
                return typeof (id) === 'string' ? `be/api/user/list/?username_eq=${id}` : `be/api/user/list/?id=${id}`
            }
        }),
        clientGroupSet: builder.mutation<StatusResponse<ValidateEmailResponse>, { userId: number, group: UserGroup }>({
            query: (data) => ({
                url: `be/api/user/list/${data.userId}/set/groups/`,
                method: 'POST',
                body: { groups: data.group ? [data.group] : [] }
            }),
            invalidatesTags: (result, error, { userId }) => [{ type: 'Users' }, { type: 'Users', id: userId }],
        }),
        getProfile: builder.query<StatusResponse<UserResponse>, void>({
            query: () => 'be/api/user/profile/',
        }),
        getProfileNotifications: builder.query<StatusResponse<{ access_requests: boolean, offers: false }>, void>({
            query: () => 'be/api/user/profile/notifications/',
        }),
        setProfileNotifications: builder.mutation<void, { access_requests: boolean, offers?: boolean }>({
            query: (data) => ({
                url: 'be/api/user/profile/notifications/',
                method: 'POST',
                body: data
            })
        }),
        getProfileAdmin: builder.query<StatusResponse<UserResponse>, number>({
            query: (id: number) => `be/api/user/admin/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Users', id }],
        }),
        validateEmail: builder.mutation<StatusResponse<ValidateEmailResponse>, EmailRequest>({
            query: (data: EmailRequest) => ({
                url: 'be/api/user/request/email/validate/',
                method: 'POST',
                body: data
            })
        }),
        validateActivation: builder.mutation<StatusResponse<void>, ValidateEmailRequest>({
            query: (data: ValidateEmailRequest) => ({
                url: 'be/api/user/profile/activation/validate/',
                method: 'POST',
                body: data
            })
        }),
        validateChangePassword: builder.mutation<StatusResponse<void>, ValidateEmailRequest>({
            query: (data: ValidateEmailRequest) => ({
                url: 'be/api/user/profile/password/reset/validate-token/',
                method: 'POST',
                body: data
            })
        }),
        confirmEmail: builder.mutation<StatusResponse<ConfimResponse>, ConfirmRequest>({
            query: (data: ConfirmRequest) => ({
                url: 'be/api/user/request/email/confirm/',
                method: 'POST',
                body: data
            })
        }),
        setProfileAdmin: builder.mutation<StatusResponse<UserResponse>, UserRequest>({
            query: (data: UserRequest) => ({
                url: `be/api/user/admin/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Users' }, { type: 'Users', id }],
        }),
        resetPassword: builder.mutation<StatusResponse<null>, number>({
            query: (id: number) => ({
                url: `be/api/user/admin/${id}/password/reset/`,
                method: 'POST',
                body: { inactivate: true }
            })
        }),
        resetProfilePassword: builder.mutation<StatusResponse<null>, { account: string, token: string }>({
            query: (data) => ({
                url: 'be/api/user/profile/password/reset/',
                method: 'POST',
                body: { account: data.account, token: data.token }
            })
        }),
        confirmPassword: builder.mutation<StatusResponse<null>, ConfirmTokenRequest>({
            query: (data: ConfirmTokenRequest) => ({
                url: 'be/api/user/profile/password/reset/confirm/',
                method: 'POST',
                body: data
            })
        }),
        confirmActivation: builder.mutation<StatusResponse<null>, ConfirmTokenRequest>({
            query: (data: ConfirmTokenRequest) => ({
                url: 'be/api/user/profile/activation/confirm/',
                method: 'POST',
                body: data
            })
        }),
        setProfilePassword: builder.mutation<StatusResponse<null>, ChangePasswordRequest>({
            query: (data: ChangePasswordRequest) => ({
                url: 'be/api/user/profile/password/set/',
                method: 'POST',
                body: data
            })
        }),
        createUserAvatar: builder.mutation<void, { file: File, onProgress?: (e: AxiosProgressEvent) => void }>({
            query(data) {
                const formData = new FormData();
                formData.append('file', data.file);
                return {
                    url: 'be/api/user/profile/avatar/',
                    method: 'PUT',
                    body: formData,
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        data.onProgress && data.onProgress(progressEvent)
                    },
                };
            },
            invalidatesTags: [{ type: 'Users' }]
        }),
        deleteUserAvatar: builder.mutation<void, void>({
            query: () => ({
                url: 'be/api/user/profile/avatar/',
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Users' }]
        }),
        setAccountApprove: builder.mutation<UserResponse, { id: number, data: ApproveRequest }>({
            query: ({ id, data }) => ({
                url: `be/api/user/request/${id}/approve/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'UserRequests' }]
        }),
        setAccountRequest: builder.mutation<StatusResponse<RequestUserResponse>, AccountRequest>({
            query: (data: AccountRequest) => ({
                url: 'be/api/user/request/',
                method: 'POST',
                body: data
            })
        }),
        deleteRequest: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/user/request/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'UserRequests' }]
        }),
        deleteUser: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/user/admin/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Users' }]
        }),
        getRequests: builder.query<StatusResponse<RequestUserResponse[]>, void>({
            query: () => 'be/api/user/request/',
            providesTags: () => [{ type: 'UserRequests' }],
        }),
        getRequest: builder.query<StatusResponse<RequestUserResponse>, number>({
            query: (id: number) => `be/api/user/request/${id}/`,
            providesTags: (result, error, id) => [{ type: 'UserRequests', id }],
        }),
    }),
})

export const { useGetUsersQuery, useGetRequestsQuery, useSetAccountRequestMutation, useSetProfilePasswordMutation,
    useDeleteRequestMutation, useDeleteUserMutation, useGetProfileQuery, useSetAccountApproveMutation, useGetProfileAdminQuery,
    useSetProfileAdminMutation, useResetPasswordMutation, useConfirmPasswordMutation, useValidateEmailMutation,
    useConfirmEmailMutation, useGetRequestQuery, useValidateActivationMutation,
    useConfirmActivationMutation, useResetProfilePasswordMutation, useLazyGetProfileQuery, useSetProfileNotificationsMutation,
    useValidateChangePasswordMutation, useGetProfileNotificationsQuery, useLazyGetProfileNotificationsQuery,
    useGetClientsQuery, useGetClientQuery, useGetClientsTotalQuery, useClientGroupSetMutation,
    useCreateUserAvatarMutation, useDeleteUserAvatarMutation,
    useLazyGetRequestsQuery, useLazyGetClientsQuery
} = userApi;
